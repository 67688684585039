exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("./../../../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-basic-drill-js": () => import("./../../../src/pages/basic-drill.js" /* webpackChunkName: "component---src-pages-basic-drill-js" */),
  "component---src-pages-collection-performance-js": () => import("./../../../src/pages/collection-performance.js" /* webpackChunkName: "component---src-pages-collection-performance-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cooperative-drill-js": () => import("./../../../src/pages/cooperative-drill.js" /* webpackChunkName: "component---src-pages-cooperative-drill-js" */),
  "component---src-pages-event-gallery-js": () => import("./../../../src/pages/event-gallery.js" /* webpackChunkName: "component---src-pages-event-gallery-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-grading-js": () => import("./../../../src/pages/grading.js" /* webpackChunkName: "component---src-pages-grading-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-video-js": () => import("./../../../src/pages/learn-video.js" /* webpackChunkName: "component---src-pages-learn-video-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-pop-song-js": () => import("./../../../src/pages/pop-song.js" /* webpackChunkName: "component---src-pages-pop-song-js" */),
  "component---src-pages-sheets-js": () => import("./../../../src/pages/sheets.js" /* webpackChunkName: "component---src-pages-sheets-js" */),
  "component---src-pages-sheets-success-js": () => import("./../../../src/pages/sheets/success.js" /* webpackChunkName: "component---src-pages-sheets-success-js" */),
  "component---src-pages-single-song-performance-js": () => import("./../../../src/pages/single-song-performance.js" /* webpackChunkName: "component---src-pages-single-song-performance-js" */),
  "component---src-pages-sub-js": () => import("./../../../src/pages/sub.js" /* webpackChunkName: "component---src-pages-sub-js" */),
  "component---src-pages-tech-etude-drill-js": () => import("./../../../src/pages/tech-etude-drill.js" /* webpackChunkName: "component---src-pages-tech-etude-drill-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-tutorial-song-js": () => import("./../../../src/pages/tutorial-song.js" /* webpackChunkName: "component---src-pages-tutorial-song-js" */)
}

